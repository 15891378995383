import React from "react";
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/widgets/layout"
import Seo from "../../components/seo"
import useTranslation from "../../hooks/useTranslation"

export const query = graphql`query WidgetPageQuery {
  hero: file(relativePath: {eq: "hero.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const WidgetPage = ({ data }) => {
  const { language } = useTranslation()
  return (
    <Layout id='widgets'>
      <Seo
        seo={{
          metaTitle: "Widgets at Rentware",
          metaDescription: "It looks like you got to the Widgets section",
        }}
      />
      <div className="pb-6 bg-secondary-bg">
        <div className="">
          <div className="pt-12 mx-auto md:pt-8 md:mt-0 max-w-screen-3xl">
            <div className="absolute z-10 w-2/3 p-8 pt-12 bg-white shadow-lg md:p-10 lg:p-16 xl:p-20 2xl:pl-48 md:w-1/2 ">
              <h1 className="primary-font">{language === "de" ? "Wählen Sie aus verschiedenen Widget-Typen" : "Pick from various widget types"}</h1>
              <p className="primary-font">{language === "de" ? "Schaffen Sie mit Widgets für Produktdetails, Last-Minute oder Produktkategorien ganz einfach ein tolles Erlebnis." : "Create a great experience easily with product details, last-minute, or product category widgets."}</p>
              <p className="primary-font">{language === "de" ? "Bitte sehen Sie sich einige unserer Widgets weiter unten an." : "Please take a closer look at some of our widgets down below."}</p>
            </div>
            <div className="w-full ml-auto max-w-2/3 md:max-w-screen-md pt-28">
              <GatsbyImage
                image={data.hero.childImageSharp.gatsbyImageData}
                className="h-96 md:min-h-56"
                alt="" />
            </div>
          </div>
        </div>

        <div className="relative max-w-screen-xl mx-auto features">

          <div className="features__item xl:px-0">
            <div className="features__text rw-text-block">
              <div className="max-w-sm p-8 pt-24 md:max-w-screen-sm md:pt-0">
                <h2 className="h1 primary-font">{language === "de" ? "Buchung eines einzelnen Tages" : "Single day booking"}</h2>
                <p className="primary-font">{language === "de" ? "Implementieren Sie Kalender-Widgets für Artikelkategorien oder direkt auf Produktebene. Mit dieser Art von Website-Element bieten Sie Ihrem Publikum die beste Erfahrung und bauen Ihren Wiedererkennungswert auf." : "Implement calendar widgets for article categories or directly on a product level. With this type of a website element, you offer your audience the best experience and build up your brand recognition."}</p>
                <Link to="./demo/single-day" className="link">{language === "de" ? "Erleben Sie eine Live-Demo" : "Experience a live demo"}</Link>
              </div>
            </div>
            <StaticImage src="../../images/widget01.png" className="order-first mx-auto widget-preview md:-ml-8" />
          </div>

          <div className="features__item xl:px-0">
            <div className="features__text rw-text-block">
              <div className="max-w-sm p-8 md:max-w-screen-sm">
                <h2 className="h1 primary-font">{language === "de" ? "Mehrtägige Buchungen" : "Multi day booking"}</h2>
                <p className="primary-font">{language === "de" ? "Genauso einfach wie die Buchung für einen Tag ist die Handhabung unseres Widgets für mehrere Tage. Das Widget erkennt automatisch Ihre Verfügbarkeitsregeln und die Belegung. Zusätzlich zur Datumsauswahl können Sie eine Zeitauswahl hinzufügen." : "As easy as single day booking is the handling of our multi day booking widget. The widget automatically detects your availability rules and occupancy. On top of the date picker you can add a time picker."}</p>
                <Link to="./demo/multi-day" className="link">{language === "de" ? "Erleben Sie eine Live-Demo" : "Experience a live demo"}</Link>
              </div>
            </div>
            <StaticImage src="../../images/widget02.png" className="order-last mx-auto widget-preview md:-mr-8" />
          </div>

        </div>
      </div>

      <div className="md:pt-32">
        <div className="relative max-w-screen-xl mx-auto features">

          <div className="features__item xl:px-0">
            <div className="bg-white features__text rw-text-block">
              <div className="max-w-sm p-8 pt-24 md:max-w-screen-sm md:pt-0">
                <h2 className="h1 primary-font">{language === "de" ? "Warenkorb und Checkout" : "Cart and checkout"}</h2>
                <p className="primary-font">{language === "de" ? "Mit den Erweiterungen für Einkaufswagen und Kasse wird Ihre Website zu einer zentralen Anlaufstelle für die Benutzeraktivierung. Ihr Publikum erhält eine optimierte und maßgeschneiderte Reise, die Teil der Produkterfahrung sein wird." : "With the cart and checkout extensions, your website becomes a go to place for user activation. Your audience gets a streamlined and customized journey that will be part of product experience."}</p>
                <Link to="./demo/cart" className="link">{language === "de" ? "Erleben Sie eine Live-Demo" : "Experience a live demo"}</Link>
              </div>
            </div>
            <StaticImage src="../../images/widget03.png" alt="" className="order-first pt-8 mx-auto widget-preview md:-ml-8" />
          </div>

          <div className="features__item xl:px-0">
            <div className="features__text rw-text-block">
              <div className="max-w-sm p-8 md:max-w-screen-sm">
                <h2 className="h1 primary-font">{language === "de" ? "Verfügbarkeit" : "Availability"}</h2>
                <p className="primary-font">{language === "de" ? "Es ist auch möglich, Verfügbarkeiten Ihres Inventars anzuzeigen, ohne einen direkten Zugang zur Buchung zu ermöglichen. Auf diese Weise können Sie Ihrem Kunden einen Überblick geben, ihn aber zwingen, Sie vor der Buchung zu kontaktieren." : "It’s also possible to show availabilities of your inventory without giving direct access to booking. That way you can give your customer a lookout but force him to contact you before booking."}</p>
                {/* <Link to="/demo/widget4/" className="link">Experience a live demo</Link> */}
              </div>
            </div>
            <StaticImage src="../../images/widget04.png" alt="" className="order-last mx-auto widget-preview md:-mr-8" />
          </div>
          {/* <div className="order-2 max-w-6xl mx-auto my-16 text-4xl text-center lg:my-28">
            <h3 className="p-4 xl:p-0">We unfold the potential of every rental business with the best software solution for all booking channels and back-office applications to make work routines almost effortless and maximize exposure and conversion.</h3>
          </div> */}
        </div>
      </div>

        <div className="relative max-w-screen-xl mx-auto features">

          <div className="features__item xl:px-0">
            <div className="features__text rw-text-block">
              <div className="max-w-sm p-8 md:max-w-screen-sm">
                <h2 className="h1 primary-font">{language === "de" ? "Last-Minute-Übersicht" : "Last minute overview"}</h2>
                <p className="primary-font">{language === "de" ? "Geben Sie einen einfachen Überblick über Ihre Last-Minute-Belegung, um die Lücken in Ihrem Zeitplan zu füllen. Sie können nur ein paar Artikel oder eine Liste einer Kategorie anbieten." : "Give a simple overview over your last minute occupancy to fill the gaps in your schedule. You can offer only a few articles or a list of a category."}</p>
                {/* <Link to="/demo/widget5/" className="link">Experience a live demo</Link> */}
              </div>
            </div>
            <StaticImage src="../../images/widget05.png" alt="" className="order-first pt-4 mx-auto widget-preview md:-ml-8" />
          </div>
          
          <div className="features__item xl:px-0">
            <div className="features__text rw-text-block">
              <div className="max-w-sm p-8 md:max-w-screen-sm">
                <h2 className="h1 primary-font">{language === "de" ? "Artikel-Karten" : "Article cards"}</h2>
                <p className="primary-font">{language === "de" ? "Kopieren Sie die Widgets Ihrer Artikel und fügen Sie sie auf einer beliebigen Website ein, um Buchungen direkt auf Ihrer Domain zu erhalten." : "Copy and paste widgets of your articles on any website and get bookings directly on your domain."}</p>
                <p className="primary-font">{language === "de" ? "Fügen Sie einfach das Artikel-Widget dort ein, wo es auf Ihrer Seite hingehört und fügen Sie das Warenkorb-Widget oben hinzu, um zu starten." : "Just put the article widget where it belongs on your page and add the cart widget at the top to start."}</p>
                <Link to="/widgets/demo/article-card/" className="link">{language === "de" ? "Erleben Sie eine Live-Demo" : "Experience a live demo"}</Link>
              </div>
            </div>
            <StaticImage src="../../images/widget06.png" alt="" className="order-last pt-4 pb-24 mx-auto widget-preview md:-mr-8 md:pt-0 md:pb-0" />
          </div>
          
          {/* <div className="order-2 max-w-6xl mx-auto my-16 text-4xl text-center lg:my-28">
            <h3 className="p-4 xl:p-0">We unfold the potential of every rental business with the best software solution for all booking channels and back-office applications to make work routines almost effortless and maximize exposure and conversion.</h3>
          </div> */}
        </div>

    </Layout>
  );
}

export default WidgetPage;
